@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --z-header: 100;
  --z-loader: 150;
  --z-networks: 10;

  --text-muted: rgba(148, 161, 178, 1);
  --background-primary: rgba(22, 22, 26, 1);
  --background-secondary: rgba(36, 38, 41, 1);
  --text-primary: rgba(255, 255, 255, 1);
  --text-secondary: rgba(114, 117, 126, 1);
  --border-color: rgba(1, 1, 1, 1);
  --accent-color: rgba(255, 99, 0, 1);

  --size: 375;
}

@media screen and (min-width: 768px) {
  :root { --size: 768; }
}

@media screen and (min-width: 1024px) {
  :root { --size: 1024; }
}

@media screen and (min-width: 1280px) {
  :root { --size: 1280; }
}

@media screen and (min-width: 1536px) {
  :root { --size: 1536; }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-muted: rgba(148, 161, 178, 1);
    --background-primary: rgba(22, 22, 26, 1);
    --background-secondary: rgba(36, 38, 41, 1);
    --text-primary: rgba(255, 255, 255, 1);
    --text-secondary: rgba(114, 117, 126, 1);
    --border-color: rgba(1, 1, 1, 1);
    --accent-color: rgba(255, 99, 0, 1);
  }
}

html {
  font-size: calc(10 * (100vw / var(--size)));
  overflow-x: hidden;
}

body {
  color: var(--text-muted);
  background-color: var(--background-primary);
}

.word { display: inline-flex; }

@layer utilities {
  .text-balance { text-wrap: balance; }

  .text-border {
    color: transparent;
    text-transform: uppercase;
    -webkit-text-stroke: 1px var(--text-primary);
  }

  .upper { text-transform: uppercase; }

  .clip { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }

  .richtext strong { color: var(--text-primary); }
}
